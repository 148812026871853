import React, { useState, useEffect } from "react";
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import InstructorProfileCard from "../courses/instructorProfileCard";
import InstructorCard from "../courses/instructorsCard";
import { Link } from "gatsby";
import useFetch from "use-http";

export default function ViewAllTeachers(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { get, response } = useFetch(`${process.env.REACT_APP_API_URL}`);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState("");
  const [instructors, setInstructors] = useState(null);
  const [categories, setCategories] = useState(null);

  const fetchInstructors = async () => {
    const _instructors = await get("/static/getInstructorsAndStudios");
    if (response.ok) {
      setInstructors(_instructors?.data?.instructors);
    } else {
      fetchInstructors();
    }
  };

  async function fetchCategories() {
    const _categories = await get("/static/coursesGroupByCategory");
    if (response.ok) {
      setCategories(_categories?.data);
    } else {
      fetchCategories();
    }
  }

  useEffect(() => {
    fetchCategories();
    fetchInstructors();
  }, []);

  const handleClickOpen = (e, data) => {
    e.preventDefault();
    setUserData(data);
    // setOpen(true);
  };

  const handleClose = () => {
    setUserData("");
    // setOpen(false);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            minWidth={"sm"}
            open={open}
            onClose={handleClose}
            scroll={isMobile ? "body" : "paper"}
            aria-labelledby="responsive-dialog-title"
            classes={{ root: classes.dialogRoot }}
          >
            <DialogContent style={{ padding: "0px" }}>
              <InstructorProfileCard
                data={userData}
                close={handleClose}
                categories={categories}
              />
            </DialogContent>
          </Dialog>
        </Grid>

        <Grid item xs={12} container>
          <Grid item xs={8}>
            <Typography
              variant="h2"
              className={isMobile ? classes.mobileHeading : classes.heading}
            >
              Instructors
            </Typography>
          </Grid>
          <Grid item xs={4} justifyContent="flex-end">
            <Link
              to={`${process.env.REACT_APP_BASE_URL}/explore/browse`}
              target="_blank"
              style={{
                textDecoration: "none",
                float: "right",
                paddingRight: "3px",
                paddingTop: "20px",
                fontSize: "18px",
              }}
            >
              View all
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={isMobile ? classes.mobileScroll : classes.scroll}
          >
            {instructors?.length > 0 ? (
              instructors?.map((instructor) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  key={instructor?.id}
                  style={{
                    minWidth: "300px",
                    maxWidth: "300px",
                    cursor: "pointer",
                  }}
                  //   onClick={(e) => handleClickOpen(e, instructor)}
                >
                  <InstructorCard data={instructor} />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} className={classes.nodata}>
                <Typography>Coming soon</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    textTransform: "uppercase",
    lineHeight: "36px",
    letterSpacing: "0.05em",
    color: "#3D3D3D",
    padding: "10px 0px 10px 5px",
  },
  mobileHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    textTransform: "uppercase",
    lineHeight: "36px",
    letterSpacing: "0.05em",
    color: "#3D3D3D",
    padding: "10px 0px 10px 5px",
  },
  scroll: {
    flexWrap: "unset",
    overflowX: "auto",
  },
  mobileScroll: {
    flexWrap: "unset",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent" /* make scrollbar transparent */,
    },
  },

  nodata: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
  },
}));
