import React, { useRef, useEffect } from "react";
import Layout from "../components/layout/index";
import { Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { graphql } from "gatsby";
import ViewAllOmidTv from "../components/viewAll/omidTv";
import ViewAllInstructors from "../components/viewAll/viewAllTeachers";
import AllCoursesByCategory from "../components/viewAll/allCoursesByCategory";
import AllChallenges from "../components/viewAll/viewAllChallenges";
import { useLocation } from "@reach/router";
import scrollTo from "gatsby-plugin-smoothscroll";

export default function Contact({ data }) {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const seoData = {
    title: data?.strapiOmidTvPage?.seo_title,
    description: data?.strapiOmidTvPage?.seo_description,
    keyword: data?.strapiOmidTvPage?.seo_tags,
    url: "/view-all"
  };
  useEffect(() => {
    location?.state?.instructor &&
      setTimeout(() => {
        scrollTo("#instructor");
      }, 100);
  });

  return (
    <Layout seoData={seoData}>
      {/* <Header /> */}
      <Grid
        container
        justify="center"
        style={{
          backgroundColor: "#FBFBFB",
          minHeight: "100vh",
          marginTop: isMobile ? "15%" : "5%",
          padding: isMobile ? "20px 5px" : "20px 30px",
        }}
      >
        <Grid item xs={12}>
          <ViewAllOmidTv title={data?.strapiSiteInfo?.omidTvTitle} />
        </Grid>

        <Grid item xs={12}>
          <AllCoursesByCategory />
        </Grid>

        <Grid item xs={12}>
          <AllChallenges />
        </Grid>



        <Grid item xs={12} id="instructor">
          <ViewAllInstructors />
        </Grid>
      </Grid>
    </Layout>
  );
}

export const query = graphql`
  {
    strapiContactsPage {
      title
      contactNo
      address
      email
      heroImage {
        url
      }
    }
    strapiSiteInfo {
      omidTvTitle
      omidTvDescription
    }
    strapiOmidTvPage {
      seo_description
      seo_tags
      seo_title
    }
  }
`;
