import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CourseCard from "../common/Card";
import useFetch from "use-http";
import CourseDetailCard from "../courses/courseDetailCard";
import { Link } from "gatsby";
import { RELATE_APP_BASE_URL } from "../../utils/constants";

export default function OmidTv() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { get, response } = useFetch(`${process.env.REACT_APP_API_URL}`);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [courseData, setCourseData] = React.useState("");
  const [categories, setCategories] = useState([]);
  const [configs, setConfigs] = useState(null);
  const [displayHover, setDisplayHover] = useState(false);

  useEffect(() => {
    async function fetchConfigs() {
      try {
        const _configs = await get("/config/");

        if (response.ok) {
          let obj = {};
          for (const item of _configs?.data) {
            obj = { ...obj, [item.key]: item.value };
          }
          setConfigs(obj);
        } else {
          fetchConfigs();
        }
      } catch (e) {}
    }

    fetchConfigs();
  }, []);
  async function fetchCategories() {
    const _categories = await get(`/static/coursesGroupByCategory`);
    if (response.ok) {
      setCategories(_categories?.data);
    } else {
      fetchCategories();
    }
  }

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleClickOpen = (e, data) => {
    e.preventDefault();
    setCourseData(data);
    setOpen(true);
  };

  const handleClose = () => {
    setCourseData("");
    setOpen(false);
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Dialog
            fullScreen={fullScreen}
            fullWidth={true}
            minWidth={"sm"}
            open={open}
            onClose={handleClose}
            scroll={isMobile ? "body" : "paper"}
            aria-labelledby="responsive-dialog-title"
            classes={{ root: classes.dialogRoot }}
          >
            <DialogContent style={{ padding: "0px" }}>
              <CourseDetailCard data={courseData} close={handleClose} />
            </DialogContent>
          </Dialog>
        </Grid>

        {categories?.length > 0 &&
          categories?.map((category) => (
            <Grid item xs={12} container key={category?.id}>
              <Grid item xs={8}>
                <Typography
                  variant="h2"
                  className={isMobile ? classes.mobileHeading : classes.heading}
                >
                  {category?.name}
                </Typography>
              </Grid>
              <Grid item xs={4} justifyContent="flex-end">
                <Link
                  to={`${process.env.REACT_APP_BASE_URL}/explore/browse`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    float: "right",
                    paddingRight: "3px",
                    paddingTop: "20px",
                    fontSize: "18px",
                  }}
                >
                  View all
                </Link>
              </Grid>
              <Grid
                item
                xs={12}
                container
                spacing={2}
                className={isMobile ? classes.mobileScroll : classes.scroll}
              >
                {category?.courses?.length > 0 ? (
                  category?.courses?.map((course) => (
                    // <div style={{ position: "relative" }}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      key={course?.id}
                      style={{
                        minWidth: "300px",
                        maxWidth: "300px",
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      {/* <Link
                        to={`/courses/${course?.slug}`}
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      > */}
                      <CourseCard
                        data={{
                          name: course?.name,
                          image: course?.image,
                          slug: course?.slug,
                          course: course,
                          configs: configs,
                        }}
                      />
                      {/* </Link> */}
                      {/* <div className={classes.btnContainer}>
                        <div className={classes.perviewBtnContainer}>
                          <Button
                            variant="contained"
                            color="default"
                            style={{
                              backgroundColor: "#FF6035",
                              borderRadius: "35px",
                            }}
                            className={
                              displayHover || isMobile
                                ? classes.previewButtonDisplay
                                : classes.previewButton
                            }
                            onClick={(e) => handleClickOpen(e, course)}
                            onMouseEnter={() => setDisplayHover(true)}
                            onMouseLeave={() => setDisplayHover(false)}
                            // startIcon={<PlayArrow />}
                          >
                            Preview
                          </Button>
                        </div>
                        <div className={classes.subscriptionBtn}>
                          <Link
                            target="_blank"
                            style={{ textDecoration: "none" }}
                            to={
                              course?.plan?.name === "Subscription"
                                ? `/subscription-plans`
                                : `${RELATE_APP_BASE_URL}/view-course/${course?.slug}?tab=signup`
                            }
                          >
                            <Button
                              className={classes.button1}
                              style={{
                                backgroundColor: "#4DB051",
                                borderRadius: "35px",
                                minWidth: "90px",
                              }}
                            >
                              {course?.plan?.name === "Paid"
                                ? `Premium: ${configs?.currency}${course?.plan?.price}`
                                : course?.plan?.name === "Subscription"
                                ? "Subscribe"
                                : course?.plan?.name === "Donation"
                                ? "Free"
                                : course?.plan?.name}
                            </Button>
                          </Link>
                        </div>
                      </div> */}
                    </Grid>
                    // </div>
                  ))
                ) : (
                  <Grid item xs={12} className={classes.nodata}>
                    <Typography>Loading...</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
      </Grid>
    </>
  );
}

// export default IndexPage

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "36px",
    letterSpacing: "0.05em",
    color: "#3D3D3D",
    padding: "10px 0px 10px 5px",
  },
  mobileHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "36px",
    letterSpacing: "0.05em",
    color: "#3D3D3D",
    padding: "10px 0px 10px 5px",
  },
  scroll: {
    flexWrap: "unset",
    overflowX: "auto",
  },
  mobileScroll: {
    flexWrap: "unset",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent" /* make scrollbar transparent */,
    },
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",

    // paddingTop: `${(157/258)*100}%`
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // width: '100%'
  },
  nodata: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
  },
  btnContainer: {
    position: "absolute",
    bottom: "62px",
    display: "flex",
    justifyContent: "space-between !important",
    width: "95%",
    alignItems: "center",
    paddingLeft: "5px",
  },
  perviewBtnContainer: {
    fontSize: "20px",
    lineHeight: "28px",
    // display: "flex",
    // justifyContent: "center",
    width: "90px",
    textDecoration: "none",
    zIndex: "10",
  },
  perviewBtn: {
    textTransform: "none",
    // width: "100%",
    // height: "67px",
    // background: theme.palette.secondary.light,
    // background: "transparent",
    borderRadius: "59px",
    background: "#FF6035",
    border: "2px solid transparent",
    color: "#FFF !important",
    // border: "2px solid transparent",
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontSize: "20px",
    height: "35px",
    // color: "#000",
    transition: "background 1s",
    width: "100px",
    // background: theme.palette.secondary.light,
    // border: "2px solid #FF6035",

    // "&:hover": {
    //   background: "#FF6035",
    //   border: "2px solid transparent",
    //   color: "#FFF !important",
    // },
  },
  button1: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
    //    height: '52px'
  },
  subscriptionBtn: {
    fontSize: "20px",
    lineHeight: "28px",
    // display: "flex",
    // justifyContent: "center",
    minWidth: "90px",
    textDecoration: "none",
    zIndex: "10",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  previewButton: {
    display: "none",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
  previewButtonDisplay: {
    display: "block",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
}));
