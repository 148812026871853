import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {
  Grid,
  Typography,
  useMediaQuery,
  CardMedia,
  CardContent,
  Tooltip,
} from "@material-ui/core";

import { RELATE_APP_BASE_URL } from "../../utils/constants";
import Truncate from "react-truncate";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  media: {
    height: 0,
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },

  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "19px",
    lineHeight: "28px",
    justifyContent: "center",
  },

  mediaImage: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

  },
}));

function CourseCard({ data }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.media}
        alt="thumbnail"
        image={
          data?.tv ? `${data?.image}` : `${RELATE_APP_BASE_URL}${data?.image}`
        }
        classes={{ media: classes.mediaImage }}

      ></CardMedia>

      <CardContent
        style={{
          backgroundColor: "#FFFFFF ",
              paddingLeft:"8px",
              paddingRight: "8px",
              paddingBottom: "8px",
              paddingTop:"8px",
              maxHeight: "50px",
              height:"50px",
              overflow:"hidden",
        }}
      >
        <Tooltip title={data?.name} aria-label="add" placement="bottom-end">
          <Typography
            variant="h2"
            className={classes.heading}
            style={{ fontSize: isMobile && "18px" }}
          >
            <Truncate lines={2} ellipsis={<span>...</span>}>
              {`   ${data?.name}   `}
            </Truncate>
          </Typography>
        </Tooltip>
      </CardContent>
    </Card>
  );
}

export default CourseCard;
