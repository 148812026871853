import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import LiveShowCard from "../common/omidTvCard";
import ReactPlayer from "react-player/lazy";
import { HighlightOffRounded, Cancel } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
// import IntroVideo from "../../assets/video/OmidIntro.mp4";

import clsx from "clsx";
const IntroVideo =
  process.env.REACT_APP_INTRO_VIDEO ||
  "https://ipfsn.omidlife.com/ipfs/Qmf8khwqMWZ1DCT4AMQinJ4TYQcrRXUD1zSFZKUCEGKKCA";
export default function OmidTv(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const fullScreen = useMediaQuery(theme.breakpoints.down("lg"))
  const [open, setOpen] = React.useState(false);
  const [userData, setUserData] = React.useState("");
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [mediaError, setMediaError] = useState("");
  const { results, resultsWithFilters } = useStaticQuery(query);
  const [currentEventUrl, setCurrentEventUrl] = useState("");
  const [allStrapiOmidTvs, setAllStrapiOmidTvs] = useState({ edges: [] });
  const [introVideo, setIntroVideo] = useState(true);
  const [endDate, setEndDate] = useState("");

  const handleClickOpen = (e, data) => {
    e.preventDefault();
    setCurrentEventUrl(data?.node?.Url);
    setUserData(data);
    setEndDate(data?.node?.isScheduled ? data?.node?.endDate : "");
    setOpen(true);
  };

  const handleClose = () => {
    setCurrentEventUrl("");
    setMediaError("");
    setUserData("");
    setOpen(false);
    setEndDate("");
    setLoadingProgress(true);
    setIntroVideo(true);
  };

  useEffect(() => {
    let filteredResults = [...resultsWithFilters?.edges].filter(
      (item) =>
        new Date() < new Date(item?.node?.endDate) &&
        new Date() > new Date(item?.node?.startDate)
    );
    let tvs = [...results?.edges, ...filteredResults].sort(
      (a, b) => a.order - b.order
    );
    setAllStrapiOmidTvs({ edges: tvs });
    setEndDate(tvs && tvs[0]?.node?.isScheduled ? tvs[0]?.node?.endDate : "");
  }, [results?.edges, resultsWithFilters?.edges]);

  const handleEnded = () => {
    if (introVideo) {
      setIntroVideo(!introVideo);
    }
  };

  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Dialog
            // fullScreen={fullScreen}
            fullWidth={true}
            minWidth={"sm"}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            classes={{ root: classes.dialogBody }}
          >
            <DialogContent>
              <div onClick={handleClose} style={{ textAlign: "right" }}>
                <Cancel style={{ color: "red", cursor: "pointer" }} />
              </div>
              <div className={classes.playerWrapper}>
                <div className={clsx(classes.reactPlayer, classes.preLoadBody)}>
                  {loadingProgress && (
                    <CircularProgress style={{ color: "#09A9BB" }} />
                  )}
                </div>
                {mediaError ? (
                  <div
                    className={clsx(classes.reactPlayer, classes.preLoadBody)}
                  >
                    <HighlightOffRounded style={{ color: "red" }} />{" "}
                    <Typography
                      style={{ paddingTop: "3px", paddingLeft: "3px" }}
                    >
                      {mediaError}
                    </Typography>
                  </div>
                ) : !endDate ? (
                  <ReactPlayer
                    controls="true"
                    className={classes.reactPlayer}
                    width="100%"
                    height="100%"
                    playing={true}
                    onStart={() => setLoadingProgress(false)}
                    // light={sessionThumbnail}
                    onEnded={handleEnded}
                    onError={() => {
                      currentEventUrl &&
                        setMediaError(`Media Url is not exist/corrupted!`);
                      setLoadingProgress(false);
                    }}
                    // url={'https://www.youtube.com/watch?v=0sYAWZXL28'}
                    url={introVideo ? IntroVideo : currentEventUrl}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1 },
                      },
                      file: {
                        attributes: {
                          preload: "none",
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  />
                ) : (
                  <ReactPlayer
                    controls="true"
                    className={classes.reactPlayer}
                    width="100%"
                    height="100%"
                    playing={true}
                    onStart={() => setLoadingProgress(false)}
                    // light={sessionThumbnail}
                    onError={() => {
                      setLoadingProgress(false);
                    }}
                    // url={'https://www.youtube.com/watch?v=0sYAWZXL28'}
                    url={introVideo ? IntroVideo : currentEventUrl}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1 },
                      },
                      file: {
                        attributes: {
                          preload: "none",
                          controlsList: "nodownload",
                        },
                      },
                    }}
                  />
                )}
              </div>
            </DialogContent>
          </Dialog>
        </Grid>
        <Grid xs={12} container justify="center">
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={isMobile ? classes.mobileHeading : classes.heading}
            >
              {props?.title}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            className={isMobile ? classes.mobileScroll : classes.scroll}
          >
            {allStrapiOmidTvs?.edges?.length > 0 ? (
              allStrapiOmidTvs?.edges?.map((event) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={3}
                  key={event?.node?.id}
                  style={{
                    minWidth: "300px",
                    maxWidth: "300px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleClickOpen(e, event)}
                >
                  <LiveShowCard
                    data={{
                      name: event?.node?.Title,
                      image: event?.node?.Thumbnail?.publicURL,
                      tv: true,
                    }}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} className={classes.nodata}>
                <Typography>Coming soon</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

// export default IndexPage

export const query = graphql`
  {
    results: allStrapiOmidTvs(
      sort: { fields: order, order: ASC }
      filter: { isScheduled: { eq: false } }
    ) {
      edges {
        node {
          id
          Url
          Title
          Description
          order
          Thumbnail {
            publicURL
          }
        }
      }
    }
    resultsWithFilters: allStrapiOmidTvs(
      sort: { fields: order, order: ASC }
      filter: { isScheduled: { eq: true } }
    ) {
      edges {
        node {
          id
          Url
          Title
          Description
          order
          isScheduled
          startDate
          endDate
          Thumbnail {
            publicURL
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "32px",
    lineHeight: "36px",
    letterSpacing: "0.05em",
    color: "#3D3D3D",
    padding: "10px 0px 10px 5px",
  },
  mobileHeading: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 900,
    fontSize: "22px",
    lineHeight: "36px",
    letterSpacing: "0.05em",
    color: "#3D3D3D",
    padding: "10px 0px 10px 5px",
  },
  scroll: {
    flexWrap: "unset",
    overflowX: "auto",
    // '&::-webkit-scrollbar': {
    //     width: '0px',
    //     background: 'transparent', /* make scrollbar transparent */
    // }
  },
  mobileScroll: {
    flexWrap: "unset",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      width: "0px",
      background: "transparent" /* make scrollbar transparent */,
    },
  },
  playerWrapper: {
    position: "relative",
    paddingTop: "56.25%",
    height: 0,
    // backgroundSize: 'cover',
    // backgroundPosition: 'center'

    // paddingTop: `${(157/258)*100}%`
  },
  reactPlayer: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    // right: 0,
    // bottom: 0,
    // width: '100%'
  },
  nodata: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50px",
  },
  dialogBody: {
    // padding: 0,
    backgroundColor: "transparent",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
  preLoadBody: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a9a9a9",
    width: "100%",
  },
}));
