import React, { useState, useEffect } from "react";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import {
  Grid,
  Typography,
  useMediaQuery,
  Dialog,
  DialogContent,
  Button,
  CardMedia,
  CardContent,
  Tooltip,
} from "@material-ui/core";
// import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import { Link } from "gatsby";
import CourseDetailCard from "../courses/courseDetailCard";

import { RELATE_APP_BASE_URL } from "../../utils/constants";
import Truncate from "react-truncate";
// import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    borderRadius: "11px",
    boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.05)",
  },
  media: {
    height: "0",
    position: "relative",
    paddingTop: "56.25%",
    width: "100%",
    BorderRadiusTopRight: "11px",
    BorderRadiusTopLeft: "11px",
    background: "#C4C4C4",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },

  heading: {
    fontFamily: theme.palette.fontFamily.Reef,
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "19px",
    lineHeight: "28px",
    display: "flex",
    justifyContent: "center",
  },
  date: {
    fontFamily: theme.palette.fontFamily.Reef,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#C2C2C2",
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  mediaImage: {
    width: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  previewButton: {
    display: "none",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
  previewButtonDisplay: {
    display: "block",
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
  },
  btnContainer: {
    position: "absolute",
    bottom: "67px",
    display: "flex",
    justifyContent: "space-between !important",
    width: "95%",
    alignItems: "center",
    paddingLeft: "5px",
  },
  perviewBtnContainer: {
    fontSize: "20px",
    lineHeight: "28px",
    // display: "flex",
    // justifyContent: "center",
    width: "90px",
    textDecoration: "none",
    zIndex: "10",
  },
  button1: {
    textTransform: "none",
    color: "#fff",
    margin: "0px 10px 10px 0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiButton-root": {
      borderRadius: "35px !important",
    },
    //    height: '52px'
  },
}));

function CourseCard({ data }) {
  console.log(data, "data");
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [displayHover, setDisplayHover] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [courseData, setCourseData] = React.useState("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [image, setImage] = useState();
  const handleClickOpen = (e, data) => {
    e.preventDefault();
    setCourseData(data);

    setOpen(true);
  };

  const handleClose = () => {
    setCourseData("");
    setOpen(false);
  };

  /*  function preloadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve(img);
        //  setIsLoading(false);
      };
      img.onerror = img.onabort = function () {
        reject(src);
      };
      img.src = src;
      var ext = src.split(".");
      var text = ext[2] + "_thumb";
      ext.splice(2, 1, text);
      let newUrl = ext.join(".");

      // let result = src.replace("png", "webp");
      setImage(src);
      // setImage(src);
    });
  } */

  /*  useEffect(() => {
    preloadImage(`${RELATE_APP_BASE_URL}${data?.image}`);
  }, []); */

  return (
    <div style={{ postion: "relative" }}>
      <Grid item xs={12}>
        <Dialog
          fullScreen={fullScreen}
          fullWidth={true}
          minWidth={"sm"}
          open={open}
          onClose={handleClose}
          scroll={isMobile ? "body" : "paper"}
          aria-labelledby="responsive-dialog-title"
          classes={{ root: classes.dialogRoot }}
        >
          <DialogContent style={{ padding: "0px" }}>
            <CourseDetailCard
              data={courseData}
              configs={data?.configs}
              close={handleClose}
            />
          </DialogContent>
        </Dialog>
      </Grid>
      <Link
        to={`/courses/${data?.slug}`}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Card
          className={classes.root}
          onMouseEnter={() => setDisplayHover(true)}
          onMouseLeave={() => setDisplayHover(false)}
        >
          <CardMedia
            className={classes.media}
            alt="thumbnail"
            image={
              data?.tv
                ? `${data?.image}`
                : `${RELATE_APP_BASE_URL}${data?.image}`
            }
            classes={{ media: classes.mediaImage }}
          ></CardMedia>
          <CardContent
            style={{
              backgroundColor: "#FFFFFF ",
              paddingLeft: "8px",
              paddingRight: "8px",
              paddingBottom: "8px",
              paddingTop: "8px",
              maxHeight: "50px",
              height: "50px",
              overflow: "hidden",
            }}
          >
            <Tooltip title={data?.name} aria-label="add" placement="bottom-end">
              <Typography
                // gutterBottom
                // variant="h6"
                // component="h6"
                variant="h2"
                className={classes.heading}
                style={{ fontSize: isMobile && "18px" }}
              >
                <Truncate lines={2} ellipsis={<span>...</span>}>
                  {`   ${data?.name}   `}
                </Truncate>
              </Typography>
            </Tooltip>
          </CardContent>
        </Card>
      </Link>
      <div
        className={classes.btnContainer}
        onMouseEnter={() => setDisplayHover(true)}
        onMouseLeave={() => setDisplayHover(false)}
      >
        <div className={classes.perviewBtnContainer}>
          <Button
            variant="contained"
            color="default"
            style={{
              backgroundColor: "#FF6035",
              borderRadius: "35px",
            }}
            aria-label="Preview"
            className={
              displayHover || isMobile
                ? classes.previewButtonDisplay
                : classes.previewButton
            }
            onClick={(e) => handleClickOpen(e, data?.course)}
            onMouseEnter={() => setDisplayHover(true)}
            onMouseLeave={() => setDisplayHover(false)}
            // startIcon={<PlayArrow />}
          >
            Preview
          </Button>
        </div>
        <div className={classes.subscriptionBtn}>
          <Link
            target="_blank"
            style={{ textDecoration: "none" }}
            to={
              data?.course?.plan?.name === "Subscription"
                ? `/subscription-plans`
                : `${RELATE_APP_BASE_URL}/view-course/${data?.course?.slug}?tab=signup`
            }
          >
            <Button
              className={classes.button1}
              style={{
                backgroundColor: "#4DB051",
                borderRadius: "35px",
                minWidth: "90px",
              }}
              aria-label="Free"
            >
              {data?.course?.plan?.name === "Paid"
                ? `Buy Premium: ${data?.configs?.currency}${data?.course?.price}`
                : data?.course?.plan?.name === "Subscription"
                ? "Subscribe"
                : data?.course?.plan?.name === "Donation"
                ? "Free"
                : data?.course?.plan?.name}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CourseCard;
